import React from "react"
import RacepageNew from "../components/racepageNew"

const TirrenoAdriatico2021 = () => {
  const startlist = [
    {
      number: " 247",
      name: " RICHEZE Maximiliano",
      team: " UAE-Team Emirates",
    },
    {
      number: " 246",
      name: " POLANC Jan",
      team: " UAE-Team Emirates",
    },
    {
      number: " 245",
      name: " OLIVEIRA Ivo",
      team: " UAE-Team Emirates",
    },
    {
      number: " 244",
      name: " MAJKA Rafał",
      team: " UAE-Team Emirates",
    },
    {
      number: " 243",
      name: " GAVIRIA Fernando",
      team: " UAE-Team Emirates",
    },
    {
      number: " 242",
      name: " FORMOLO Davide",
      team: " UAE-Team Emirates",
    },
    {
      number: " 241",
      name: " POGAČAR Tadej",
      team: " UAE-Team Emirates",
    },
    {
      number: " 237",
      name: " SKUJIŅŠ Toms",
      team: " Trek - Segafredo",
    },
    {
      number: " 236",
      name: " SIMMONS Quinn",
      team: " Trek - Segafredo",
    },
    {
      number: " 235",
      name: " MULLEN Ryan",
      team: " Trek - Segafredo",
    },
    {
      number: " 234",
      name: " MOSCHETTI Matteo",
      team: " Trek - Segafredo",
    },
    {
      number: " 233",
      name: " LIEPIŅŠ Emīls",
      team: " Trek - Segafredo",
    },
    {
      number: " 232",
      name: " CICCONE Giulio",
      team: " Trek - Segafredo",
    },
    {
      number: " 231",
      name: " NIBALI Vincenzo",
      team: " Trek - Segafredo",
    },
    {
      number: " 227",
      name: " SIMON Julien",
      team: " Team Total Direct Energie",
    },
    {
      number: " 226",
      name: " RODRÍGUEZ Cristián",
      team: " Team Total Direct Energie",
    },
    {
      number: " 225",
      name: " PETIT Adrien",
      team: " Team Total Direct Energie",
    },
    {
      number: " 224",
      name: " MANZIN Lorrenzo",
      team: " Team Total Direct Energie",
    },
    {
      number: " 223",
      name: " DE LA PARTE Víctor",
      team: " Team Total Direct Energie",
    },
    {
      number: " 222",
      name: " BONIFAZIO Niccolò",
      team: " Team Total Direct Energie",
    },
    {
      number: " 221",
      name: " TERPSTRA Niki",
      team: " Team Total Direct Energie",
    },
    {
      number: " 217",
      name: " WIŚNIOWSKI Łukasz",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 216",
      name: " VINJEBO Emil",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 215",
      name: " CLAEYS Dimitri",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 214",
      name: " LINDEMAN Bert-Jan",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 213",
      name: " GOGL Michael",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 212",
      name: " CLARKE Simon",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 211",
      name: " POZZOVIVO Domenico",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 207",
      name: " TUSVELD Martijn",
      team: " Team DSM",
    },
    {
      number: " 206",
      name: " NIEUWENHUIS Joris",
      team: " Team DSM",
    },
    {
      number: " 205",
      name: " KANTER Max",
      team: " Team DSM",
    },
    {
      number: " 204",
      name: " HAMILTON Chris",
      team: " Team DSM",
    },
    {
      number: " 203",
      name: " DENZ Nico",
      team: " Team DSM",
    },
    {
      number: " 202",
      name: " ARNDT Nikias",
      team: " Team DSM",
    },
    {
      number: " 201",
      name: " BARDET Romain",
      team: " Team DSM",
    },
    {
      number: " 197",
      name: " ROSA Diego",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 196",
      name: " OWSIAN Łukasz",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 195",
      name: " LEDANOIS Kévin",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 194",
      name: " GUERNALEC Thibault",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 193",
      name: " PICHON Laurent",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 192",
      name: " BOUDAT Thomas",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 191",
      name: " QUINTANA Nairo",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 187",
      name: " VILLELLA Davide",
      team: " Movistar Team",
    },
    {
      number: " 186",
      name: " TORRES Albert",
      team: " Movistar Team",
    },
    {
      number: " 185",
      name: " SOLER Marc",
      team: " Movistar Team",
    },
    {
      number: " 184",
      name: " SERRANO Gonzalo",
      team: " Movistar Team",
    },
    {
      number: " 183",
      name: " OLIVEIRA Nelson",
      team: " Movistar Team",
    },
    {
      number: " 182",
      name: " CATALDO Dario",
      team: " Movistar Team",
    },
    {
      number: " 181",
      name: " GARCÍA CORTINA Iván",
      team: " Movistar Team",
    },
    {
      number: " 177",
      name: " VAN MOER Brent",
      team: " Lotto Soudal",
    },
    {
      number: " 176",
      name: " VAN DER SANDE Tosh",
      team: " Lotto Soudal",
    },
    {
      number: " 175",
      name: " KLUGE Roger",
      team: " Lotto Soudal",
    },
    {
      number: " 174",
      name: " FRISON Frederik",
      team: " Lotto Soudal",
    },
    {
      number: " 173",
      name: " EWAN Caleb",
      team: " Lotto Soudal",
    },
    {
      number: " 172",
      name: " DE BUYST Jasper",
      team: " Lotto Soudal",
    },
    {
      number: " 171",
      name: " WELLENS Tim",
      team: " Lotto Soudal",
    },
    {
      number: " 167",
      name: " VAN HOOYDONCK Nathan",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 166",
      name: " ROOSEN Timo",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 165",
      name: " MARTENS Paul",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 164",
      name: " GESINK Robert",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 163",
      name: " FOSS Tobias",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 162",
      name: " AFFINI Edoardo",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 161",
      name: " VAN AERT Wout",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 157",
      name: " WÜRTZ SCHMIDT Mads",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 156",
      name: " HOFSTETTER Hugo",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 155",
      name: " DE MARCHI Alessandro",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 154",
      name: " CIMOLAI Davide",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 153",
      name: " BIERMANS Jenthe",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 152",
      name: " NIV Guy",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 151",
      name: " BOIVIN Guillaume",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 147",
      name: " VLIEGEN Loïc",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 146",
      name: " VANSPEYBROUCK Pieter",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 145",
      name: " ROTA Lorenzo",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 144",
      name: " PASQUALON Andrea",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 143",
      name: " HIRT Jan",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 142",
      name: " DE GENDT Aimé",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 141",
      name: " BAKELANTS Jan",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 137",
      name: " THOMAS Geraint",
      team: " INEOS Grenadiers",
    },
    {
      number: " 136",
      name: " SIVAKOV Pavel",
      team: " INEOS Grenadiers",
    },
    {
      number: " 135",
      name: " PUCCIO Salvatore",
      team: " INEOS Grenadiers",
    },
    {
      number: " 134",
      name: " KWIATKOWSKI Michał",
      team: " INEOS Grenadiers",
    },
    {
      number: " 133",
      name: " GANNA Filippo",
      team: " INEOS Grenadiers",
    },
    {
      number: " 132",
      name: " CASTROVIEJO Jonathan",
      team: " INEOS Grenadiers",
    },
    {
      number: " 131",
      name: " BERNAL Egan",
      team: " INEOS Grenadiers",
    },
    {
      number: " 127",
      name: " THOMAS Benjamin",
      team: " Groupama - FDJ",
    },
    {
      number: " 126",
      name: " MOLARD Rudy",
      team: " Groupama - FDJ",
    },
    {
      number: " 125",
      name: " MADOUAS Valentin",
      team: " Groupama - FDJ",
    },
    {
      number: " 124",
      name: " LUDVIGSSON Tobias",
      team: " Groupama - FDJ",
    },
    {
      number: " 123",
      name: " KÜNG Stefan",
      team: " Groupama - FDJ",
    },
    {
      number: " 122",
      name: " GENIETS Kevin",
      team: " Groupama - FDJ",
    },
    {
      number: " 121",
      name: " PINOT Thibaut",
      team: " Groupama - FDJ",
    },
    {
      number: " 117",
      name: " VELASCO Simone",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 116",
      name: " ROVNY Ivan",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 115",
      name: " NEKRASOV Denis",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 114",
      name: " KREUZIGER Roman",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 113",
      name: " KOCHETKOV Pavel",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 112",
      name: " CANOLA Marco",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 111",
      name: " ZAKARIN Ilnur",
      team: " Gazprom - RusVelo",
    },
    {
      number: " 107",
      name: " ROPERO Alejandro",
      team: " EOLO-Kometa",
    },
    {
      number: " 106",
      name: " RIVI Samuele",
      team: " EOLO-Kometa",
    },
    {
      number: " 105",
      name: " CHRISTIAN Mark",
      team: " EOLO-Kometa",
    },
    {
      number: " 104",
      name: " BAIS Davide",
      team: " EOLO-Kometa",
    },
    {
      number: " 103",
      name: " ARCHIBALD John",
      team: " EOLO-Kometa",
    },
    {
      number: " 102",
      name: " ALBANESE Vincenzo",
      team: " EOLO-Kometa",
    },
    {
      number: " 101",
      name: " BELLETTI Manuel",
      team: " EOLO-Kometa",
    },
    {
      number: " 97",
      name: " SCULLY Tom",
      team: " EF Education - Nippo",
    },
    {
      number: " 96",
      name: " LANGEVELD Sebastian",
      team: " EF Education - Nippo",
    },
    {
      number: " 95",
      name: " HOWES Alex",
      team: " EF Education - Nippo",
    },
    {
      number: " 94",
      name: " CRADDOCK Lawson",
      team: " EF Education - Nippo",
    },
    {
      number: " 93",
      name: " CARR Simon",
      team: " EF Education - Nippo",
    },
    {
      number: " 92",
      name: " BETTIOL Alberto",
      team: " EF Education - Nippo",
    },
    {
      number: " 91",
      name: " HIGUITA Sergio",
      team: " EF Education - Nippo",
    },
    {
      number: " 87",
      name: " VAN LERBERGHE Bert",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 86",
      name: " ŠTYBAR Zdeněk",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 85",
      name: " HODEG Álvaro José",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 84",
      name: " BALLERINI Davide",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 83",
      name: " ASGREEN Kasper",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 82",
      name: " ALMEIDA João",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 81",
      name: " ALAPHILIPPE Julian",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 77",
      name: " VIVIANI Attilio",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 76",
      name: " VANBILSEN Kenneth",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 75",
      name: " SABATINI Fabio",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 74",
      name: " HAAS Nathan",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 73",
      name: " DRUCKER Jempy",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 72",
      name: " BOHLI Tom",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 71",
      name: " VIVIANI Elia",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 67",
      name: " OSS Daniel",
      team: " BORA - hansgrohe",
    },
    {
      number: " 66",
      name: " KONRAD Patrick",
      team: " BORA - hansgrohe",
    },
    {
      number: " 65",
      name: " FABBRO Matteo",
      team: " BORA - hansgrohe",
    },
    {
      number: " 64",
      name: " BURGHARDT Marcus",
      team: " BORA - hansgrohe",
    },
    {
      number: " 63",
      name: " BODNAR Maciej",
      team: " BORA - hansgrohe",
    },
    {
      number: " 62",
      name: " ALEOTTI Giovanni",
      team: " BORA - hansgrohe",
    },
    {
      number: " 61",
      name: " SAGAN Peter",
      team: " BORA - hansgrohe",
    },
    {
      number: " 57",
      name: " WRIGHT Fred",
      team: " Bahrain - Victorious",
    },
    {
      number: " 56",
      name: " TRATNIK Jan",
      team: " Bahrain - Victorious",
    },
    {
      number: " 55",
      name: " PADUN Mark",
      team: " Bahrain - Victorious",
    },
    {
      number: " 54",
      name: " CAPECCHI Eros",
      team: " Bahrain - Victorious",
    },
    {
      number: " 53",
      name: " CARUSO Damiano",
      team: " Bahrain - Victorious",
    },
    {
      number: " 52",
      name: " BILBAO Pello",
      team: " Bahrain - Victorious",
    },
    {
      number: " 51",
      name: " LANDA Mikel",
      team: " Bahrain - Victorious",
    },
    {
      number: " 47",
      name: " MARTINELLI Davide",
      team: " Astana - Premier Tech",
    },
    {
      number: " 46",
      name: " IZAGIRRE Gorka",
      team: " Astana - Premier Tech",
    },
    {
      number: " 45",
      name: " HOULE Hugo",
      team: " Astana - Premier Tech",
    },
    {
      number: " 44",
      name: " FELLINE Fabio",
      team: " Astana - Premier Tech",
    },
    {
      number: " 43",
      name: " BOARO Manuele",
      team: " Astana - Premier Tech",
    },
    {
      number: " 42",
      name: " ARANBURU Alex",
      team: " Astana - Premier Tech",
    },
    {
      number: " 41",
      name: " FUGLSANG Jakob",
      team: " Astana - Premier Tech",
    },
    {
      number: " 37",
      name: " TESFATSION Natnael",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 36",
      name: " SEPÚLVEDA Eduardo",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 35",
      name: " PELLAUD Simon",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 34",
      name: " MUÑOZ Daniel",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 33",
      name: " MALUCELLI Matteo",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 32",
      name: " BISOLTI Alessandro",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 31",
      name: " BAIS Mattia",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      number: " 27",
      name: " VERMEERSCH Gianni",
      team: " Alpecin-Fenix",
    },
    {
      number: " 26",
      name: " VERGAERDE Otto",
      team: " Alpecin-Fenix",
    },
    {
      number: " 25",
      name: " VAKOČ Petr",
      team: " Alpecin-Fenix",
    },
    {
      number: " 24",
      name: " RICKAERT Jonas",
      team: " Alpecin-Fenix",
    },
    {
      number: " 23",
      name: " MEURISSE Xandro",
      team: " Alpecin-Fenix",
    },
    {
      number: " 22",
      name: " MERLIER Tim",
      team: " Alpecin-Fenix",
    },
    {
      number: " 21",
      name: " VAN DER POEL Mathieu",
      team: " Alpecin-Fenix",
    },
    {
      number: " 17",
      name: " WARBASSE Larry",
      team: " AG2R Citroën Team",
    },
    {
      number: " 16",
      name: " VENDRAME Andrea",
      team: " AG2R Citroën Team",
    },
    {
      number: " 15",
      name: " SCHÄR Michael",
      team: " AG2R Citroën Team",
    },
    {
      number: " 14",
      name: " PETERS Nans",
      team: " AG2R Citroën Team",
    },
    {
      number: " 13",
      name: " CALMEJANE Lilian",
      team: " AG2R Citroën Team",
    },
    {
      number: " 12",
      name: " BOUCHARD Geoffrey",
      team: " AG2R Citroën Team",
    },
    {
      number: " 11",
      name: " VAN AVERMAET Greg",
      team: " AG2R Citroën Team",
    },
    {
      number: " 7",
      name: " ZEITS Andrey",
      team: " Team BikeExchange",
    },
    {
      number: " 6",
      name: " STANNARD Robert",
      team: " Team BikeExchange",
    },
    {
      number: " 5",
      name: " MEZGEC Luka",
      team: " Team BikeExchange",
    },
    {
      number: " 4",
      name: " JUUL-JENSEN Christopher",
      team: " Team BikeExchange",
    },
    {
      number: " 3",
      name: " HEPBURN Michael",
      team: " Team BikeExchange",
    },
    {
      number: " 2",
      name: " BAUER Jack",
      team: " Team BikeExchange",
    },
    {
      number: " 1",
      name: " YATES Simon",
      team: " Team BikeExchange",
    },
  ]

  const race = "Tirreno-Adriatico"

  return (
    <RacepageNew
      title="Tirreno - Adriatico 2021"
      startlist={startlist}
      race={race}
    />
  )
}

export default TirrenoAdriatico2021
